import axios from 'axios';

export class LoteService {
    async obtenerLotesInsumo(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/lote/lotes-insumo/${id}`)
            .then((response) => response)
            .catch((error) => error.response);
    }

    async allLotes() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/lote/all`)
            .then((response) => response)
            .catch((error) => error.response);
    }
    async proximosVencer() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/lote/proximos-vencer`)
            .then((response) => response)
            .catch((error) => error.response);
    }
    async vencidos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/lote/vencidos`)
            .then((response) => response)
            .catch((error) => error.response);
    }
}
