import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { RecintoService } from "../service/RecintoService";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Password } from "primereact/password";
import { DialogProcesando } from "../components/DialogProcesando";
import { InputSwitch } from "primereact/inputswitch";

const recintoService = new RecintoService();

export const Recinto = () => {
    const recintoVacio = {
        idRecinto: null,
        nombre: "",
        activo: null,
    };

    const [globalFilter, setGlobalFilter] = useState(null);
    const [recintos, setRecintos] = useState([]);
    const [recinto, setRecinto] = useState(recintoVacio);
    const [recintoDialog, setRecintoDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loadingRecinto, setLoadingRecinto] = useState(true);
    const [loadingRecintoGuardar, setLoadingRecintoGuardar] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        recintoService.obtenerRecintos().then(({ data, status }) => {
            if (status >= 200 && status < 300) {
                setRecintos(data);
                setLoadingRecinto(false);
            } else {
                status !== 403 &&
                    data.errors.forEach((element) => {
                        toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                        return;
                    });
            }
        });
    }, []);
    const estadoBodyTemplate = ({ activo }) => {
        let text = "";
        activo ? (text = "Desactivar recinto") : (text = "Activar recinto");
        return (
            <>
                <span className="p-column-title">Estado</span>
                <InputSwitch checked={activo} tooltip={text} />
            </>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de usuarios</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const openNuevo = () => {
        setRecinto(recintoVacio);
        setSubmitted(false);
        setRecintoDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNuevo} />
            </React.Fragment>
        );
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _recinto = { ...recinto };
        _recinto[`${name}`] = val;

        setRecinto(_recinto);
    };

    const guardarUsuario = () => {
        setSubmitted(true);
        if (!recinto.nombre) {
            return;
        }

        setLoadingRecintoGuardar(true);
        recintoService.recinto(recinto).then(({ data, status }) => {
            setLoadingRecintoGuardar(false);
            if (status >= 200 && status < 300) {
                toast.current.show({ severity: "success", summary: "Éxito", detail: "El nuevo recinto se ha registrado con éxito en el sistema", life: 3000 });
                setRecintos([...recintos, data.recinto]);
                setRecintoDialog(false);
                setSubmitted(false);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                });
                return;
            }
        });
    };

    const ocultarDialogRecinto = () => {
        setSubmitted(false);
        setRecintoDialog(false);
    };

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={ocultarDialogRecinto} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={guardarUsuario} />
        </>
    );


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={recintos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Usuarios"
                        globalFilter={globalFilter}
                        emptyMessage="Sin recintos."
                        loading={loadingRecinto}
                        header={header}
                    >
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="activo" header="Estado" body={estadoBodyTemplate} sortable></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingRecinto} />
                    <DialogProcesando visible={loadingRecintoGuardar} />

                    <Dialog visible={recintoDialog} style={{ width: "450px" }} header="Crear un nuevo recinto" modal className="p-fluid" footer={usuarioDialogFooter} onHide={ocultarDialogRecinto}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={recinto.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !recinto.nombre })} />
                            {submitted && !recinto.nombre && <small className="p-invalid red">El nombre es requerido.</small>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
