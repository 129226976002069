import axios from 'axios';

export class BajaService {
    async obtenerSalidas() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/baja/bajas`)
            .then((response) => response)
            .catch((error) => error.response);
    }

    async create(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/baja/`, {...data, date: new Date()})
            .then((response) => response)
            .catch((error) => error.response);
    }
}
