import React, { useEffect, useRef, useState } from "react";
import { SolicitudService } from "../service/SolicitudService";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DialogProcesando } from "../components/DialogProcesando";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";

const solicitudService = new SolicitudService();

const solicitudVacia = {
    idSolicitud: null,
    codigo_solicitud: "",
    fecha: null,
    estado: "",
    usuario: {
        rut: "",
        nombre: "",
    },
    recinto: {
        nombre: "",
    },
};

export const Solicitudes = () => {
    const [solicitudes, setSolicitudes] = useState([]);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterDetalle, setGlobalFilterDetalle] = useState(null);
    const [loadingSolicitudes, setLoadingSolicitudes] = useState(true);
    const [loadingCargando, setLoadingCargando] = useState(false);
    const [insumos, setInsumos] = useState([]);
    const [solicitud, setSolicitud] = useState(solicitudVacia);
    const [detalleDialog, setDetalleDialog] = useState(false);
    const dt = useRef(null);

    useEffect(() => {
        setLoadingCargando(true);
        solicitudService.obtenerSolicitudes().then(({ status, data }) => {
            setLoadingCargando(false);
            if (status >= 200 && status < 300) {
                setSolicitudes(data);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingSolicitudes(false);
        });
    }, []);

    const fechaBodyTemplate = ({ fecha }) => {
        let _fecha = new Date(fecha);
        return (
            <>
                <span className="p-column-title">Fecha de recepcion</span>
                {_fecha.toLocaleDateString('es-CL') + " " + _fecha.toLocaleTimeString('es-CL')}
            </>
        );
    };

    const fecha = (fecha) => {
        let _fecha = new Date(fecha);
        return _fecha.toLocaleDateString('es-CL') + " " + _fecha.toLocaleTimeString('es-CL');
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de solicitudes.</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar solicitud..." />
            </span>
        </div>
    );

    const headerDetalle = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de insumos.</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilterDetalle(e.target.value)} placeholder="Buscar insumo..." />
            </span>
        </div>
    );

    const detallesSolicitud = (rowData) => {
        setLoadingCargando(true);
        solicitudService.obtenerSolicitud(rowData.idSolicitud).then(({ status, data }) => {
            const { insumos, solicitud } = data;
            setLoadingCargando(false);
            if (status >= 200 && status < 300) {
                setInsumos(insumos);
                setSolicitud(solicitud);
                setDetalleDialog(true);
            }
        });
    };

    const ocultarDialogDetalle = () => {
        setDetalleDialog(false);
    };

    const detallesBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-list" className="p-button-rounded p-button-success p-mr-2" onClick={() => detallesSolicitud(rowData)} />
            </div>
        );
    };

    const idBodyTemplate = (rowData) => {
        return rowData.estado === 'SOLICITADO' ? <Link to={`/reparto/${rowData.idSolicitud}`}>{rowData.codigo_solicitud}</Link> : rowData.codigo_solicitud;
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" /* left={leftToolbarTemplate} */></Toolbar>

                    <DataTable
                        ref={dt}
                        value={solicitudes}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Solicitudes"
                        globalFilter={globalFilter}
                        emptyMessage="Sin solicitudes."
                        loading={loadingSolicitudes}
                        header={header}
                    >
                        <Column field="codigo_solicitud" body={idBodyTemplate} header="Codigo Recepción" sortable></Column>
                        <Column field="usuario.rut" header="Rut Responsable" sortable></Column>
                        <Column field="usuario.nombre" header="Nombre Responsable" sortable></Column>
                        <Column field="fecha" body={fechaBodyTemplate} header="Fecha" sortable></Column>
                        <Column field="recinto.nombre" header="Recinto Solicitante" sortable></Column>
                        <Column field="estado" header="Estado" sortable></Column>
                        <Column body={detallesBodyTemplate} header="Detalles" sortable></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingCargando} />

                    <Dialog visible={detalleDialog} style={{ width: "1000px" }} header={`Detalles solicitud: ${solicitud.codigo_solicitud}`} modal className="p-fluid" onHide={ocultarDialogDetalle}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre Responsable</label>
                            <InputText id="nombre" disabled value={solicitud.usuario.nombre} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="rut">Rut Responsable</label>
                            <InputText id="rut" disabled value={solicitud.usuario.rut} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="fecha">Fecha</label>
                            <InputText id="fecha" disabled value={fecha(solicitud.fecha)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="estado">Estado</label>
                            <InputText id="estado" disabled value={solicitud.estado} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="codigo_solicitud">Codigo Solicitud</label>
                            <InputText id="codigo_solicitud" disabled value={solicitud.codigo_solicitud} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="recinto">Recinto</label>
                            <InputText id="recinto" disabled value={solicitud.recinto} />
                        </div>

                        <DataTable
                            ref={dt}
                            value={insumos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} insumos"
                            globalFilter={globalFilterDetalle}
                            emptyMessage="Sin insumos."
                            header={headerDetalle}
                        >
                            <Column field="insumo.nombre" header="Nombre Insumo" sortable></Column>
                            <Column field="insumo.grupo.nombre" header="Grupo" sortable></Column>
                            <Column field="cantidad" header="Cantidad Solicitada" sortable></Column>
                        </DataTable>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
