import axios from "axios";
export class RecepcionSevice {
    async obtenerRecepciones() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/recepcion/recepciones`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async guardarRecepcion(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/recepcion/`, {...data, date: new Date()})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async verDetallesDeRecepcion({idRecepcion}) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/recepcion/${idRecepcion}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
}
