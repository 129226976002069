import React, { useRef, useState } from "react";
import { RecepcionSevice } from "../service/RecepcionService";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DialogProcesando } from "../components/DialogProcesando";
import { Dialog } from "primereact/dialog";
import { InsumoService } from "../service/InsumoService";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";

const recepcionService = new RecepcionSevice();

const loteVacio = {
    codigo_lote: "",
    fecha_vencimiento: null,
    cantidad: null,
    insumo_id: null,
    insumo: {
        nombre: "",
        grupo: {
            nombre: "",
        },
    },
};

export const Recepcion = () => {
    const [lotes, setLotes] = useState([]);
    const [lote, setLote] = useState(loteVacio);
    const [insumos, setInsumos] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterInsumos, setGlobalFilterInsumos] = useState(null);
    const [loadingCargando, setLoadingCargando] = useState(false);
    const [insumoDialog, setInsumoDialog] = useState(false);
    const [loteDialog, setLoteDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [quitarLoteDialog, setQuitarLoteDialog] = useState(false);
    const [loteQuitar, setLoteQuitar] = useState(null);
    const [factura, setFactura] = useState("");
    const toast = useRef(null);
    const dt = useRef(null);

    const guardarRecepcion = () => {
        setSubmitted(false);
        if (!factura.length) {
            toast.current.show({ severity: "error", summary: "Error", detail: "La factura es obligatoria", life: 3000 });
            setSubmitted(true);
            return;
        }

        if (!lotes.length) {
            toast.current.show({ severity: "error", summary: "Error", detail: "La recepcion no contiene lotes", life: 3000 });
            return;
        }

        setLoadingCargando(true);
        let _data = {
            factura,
            lotes,
        };
        recepcionService.guardarRecepcion(_data).then(({ data, status }) => {
            setLoadingCargando(false);
            if (status >= 200 && status < 300) {
                setLotes([]);
                setFactura("");
                toast.current.show({ severity: "success", summary: "Éxito", detail: data.msg, life: 3000 });
            } else {
                data.errors?.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
        });
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de lotes agregados</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar lote..." />
            </span>
        </div>
    );
    const headerInsumos = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de insumos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilterInsumos(e.target.value)} placeholder="Buscar insumo..." />
            </span>
        </div>
    );

    const openInsumos = () => {
        setLoadingCargando(true);
        const insumoService = new InsumoService();
        insumoService.obtenerInsumos().then(({ data, status }) => {
            setLoadingCargando(false);
            if (status >= 200 && status < 300) {
                setInsumos(data);
                setInsumoDialog(true);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
        });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openInsumos} />
            </React.Fragment>
        );
    };

    const rigthToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Guardar recepción" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={guardarRecepcion} />
            </React.Fragment>
        );
    };

    const ocultarDialogInsumos = () => {
        setInsumoDialog(false);
    };

    const agregarLote = (_insumo) => {
        let _lote = { ...lote, insumo_id: _insumo.idInsumo, insumo: { nombre: _insumo.nombre, grupo: { nombre: _insumo.grupo.nombre } } };
        setLote(_lote);
        setLoteDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" className="p-button-rounded p-button-success p-mr-2" onClick={() => agregarLote(rowData)} />
            </div>
        );
    };

    const quitarLote = ({ codigo_lote }) => {
        setLoadingCargando(true);
        let _lotes = lotes.filter((lote) => lote.codigo_lote !== codigo_lote);
        setLotes(_lotes);
        setLoadingCargando(false);
        setQuitarLoteDialog(false);
        toast.current.show({ severity: "success", summary: "Éxito", detail: "El lote se ha quitado correctamente", life: 3000 });
    };

    const quitarLoteBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger p-mr-2"
                    onClick={() => {
                        setLoteQuitar(rowData);
                        setQuitarLoteDialog(true);
                    }}
                />
            </div>
        );
    };

    const ocultarDialogLote = () => {
        setLoteDialog(false);
        setLote(loteVacio);
        setSubmitted(false);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _lote = { ...lote };
        _lote[`${name}`] = val;

        setLote(_lote);
    };

    const onFacturaChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setFactura(val);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _lote = { ...lote };
        _lote[`${name}`] = val;

        setLote(_lote);
    };

    const registrarLote = () => {
        setLoadingCargando(true);
        setSubmitted(true);
        if (!lote.cantidad || !lote.fecha_vencimiento || !lote.codigo_lote) {
            setLoadingCargando(false);
            toast.current.show({ severity: "error", summary: "Error", detail: "Complete todos los campos solicitados", life: 3000 });
            return;
        }

        setLotes([...lotes, lote]);
        setLoteDialog(false);
        setInsumoDialog(false);
        setLote(loteVacio);

        setSubmitted(false);
        setLoadingCargando(false);
        toast.current.show({ severity: "success", summary: "Éxito", detail: "Lote agregado con éxito", life: 3000 });
    };

    const loteFooterDialog = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={ocultarDialogLote} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={registrarLote} />
        </>
    );

    const fechaBodyTemplate = ({ fecha_vencimiento }) => {
        let _fecha = new Date(fecha_vencimiento);
        return (
            <>
                <span className="p-column-title">Fecha de Vencimiento</span>
                {_fecha.toLocaleDateString('es-CL')}
            </>
        );
    };

    const hideDeleteProductsDialog = () => {
        setQuitarLoteDialog(false);
    };

    const deleteProductsDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Quitar" icon="pi pi-check" className="p-button-outlined p-button-danger" onClick={() => quitarLote(loteQuitar)} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rigthToolbarTemplate}></Toolbar>

                    <div>
                        <div className="p-grid">
                            <div className="p-col-6">
                                <div className="card p-fluid">
                                    <div className="p-field">
                                        <label htmlFor="factura">Factura</label>
                                        <InputText id="factura" value={factura} onChange={(e) => onFacturaChange(e)} required className={classNames({ "p-invalid": submitted && !factura })} />
                                        {submitted && !factura && <small className="p-error">La factura es requerida.</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <DataTable
                        ref={dt}
                        value={lotes}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} lotes agregados"
                        globalFilter={globalFilter}
                        emptyMessage="Sin lotes agregados."
                        header={header}
                    >
                        <Column field="codigo_lote" header="Codigo del Lote" sortable></Column>
                        <Column field="insumo.nombre" header="Nombre" sortable></Column>
                        <Column field="insumo.grupo.nombre" header="Grupo" sortable></Column>
                        <Column field="fecha_vencimiento" header="Fecha de Vencimiento" body={fechaBodyTemplate} sortable></Column>
                        <Column field="cantidad" header="Cantidad" sortable></Column>
                        <Column header="Quitar" body={quitarLoteBodyTemplate} sortable></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingCargando} />

                    <Dialog visible={insumoDialog} style={{ width: "1000px" }} header="Insumos" modal className="p-fluid" onHide={ocultarDialogInsumos}>
                        <DataTable
                            ref={dt}
                            value={insumos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} insumos"
                            globalFilter={globalFilterInsumos}
                            emptyMessage="Sin insumos."
                            header={headerInsumos}
                        >
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="grupo.nombre" header="Grupo" sortable></Column>
                            <Column header="Seleccionar Insumo" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={loteDialog} style={{ width: "450px" }} header="Crear un nuevo usuario" modal className="p-fluid" footer={loteFooterDialog} onHide={ocultarDialogLote}>
                        <div className="p-field">
                            <label htmlFor="codigo_lote">Codigo Lote</label>
                            <InputText id="codigo_lote" value={lote.codigo_lote} onChange={(e) => onInputChange(e, "codigo_lote")} required className={classNames({ "p-invalid": submitted && !lote.codigo_lote })} />
                            {submitted && !lote.nombre && <small className="p-error">El codigo del lote es requerido.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="fecha_vencimiento">Fecha de Vencimiento</label>
                            <Calendar
                                id="fecha_vencimiento"
                                value={lote.fecha_vencimiento}
                                onChange={(e) => onInputChange(e, "fecha_vencimiento")}
                                view="month"
                                dateFormat="mm/yy"
                                yearNavigator
                                yearRange="2015:2030"
                                required
                                className={classNames({ "p-invalid": submitted && !lote.fecha_vencimiento })}
                            />
                            {submitted && !lote.fecha_vencimiento && <small className="p-error">La fecha de vencimiento es requerida.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <InputNumber id="cantidad" feedback={false} toggleMask value={lote.cantidad} onValueChange={(e) => onInputNumberChange(e, "cantidad")} required className={classNames({ "p-invalid": submitted && !lote.cantidad })} />
                            {submitted && !lote.cantidad && <small className="p-error">La cantidad es requerida.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" feedback={false} disabled={true} toggleMask value={lote.insumo.nombre} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="grupo">Grupo</label>
                            <InputText id="grupo" feedback={false} disabled={true} toggleMask value={lote.insumo.grupo.nombre} />
                        </div>
                    </Dialog>

                    <Dialog visible={quitarLoteDialog} style={{ width: "450px" }} header="Confirmar" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            <span>¿Seguro que desea quitar el lote con codigo: {loteQuitar?.codigo_lote}?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
