import axios from "axios";
export class UsuarioService {
    async obtenerUsuarios() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/usuario/obtener-usuarios`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async login(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/auth`, {...data, date: new Date()})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async guardarNuevoUsuario(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/usuario/`, {...data, date: new Date()})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async cambiarEstadoUsuario(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/usuario/activarDesactivarCuenta`, {...data, date: new Date()})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
}
