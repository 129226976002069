import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { UsuarioService } from "../service/UsuarioService";
import { RecintoService } from "../service/RecintoService";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Password } from "primereact/password";
import { DialogProcesando } from "../components/DialogProcesando";
import { InputSwitch } from "primereact/inputswitch";

const usuarioService = new UsuarioService();

export const Usuario = () => {
    const usuarioVacio = {
        activo: null,
        idUsuario: null,
        nombre: "",
        rut: "",
        perfil_id: null,
        perfil: {
            idPerfil: null,
            nombre: "",
        },
        recinto: {
            idRecinto: null,
            nombre: "",
        },
        recinto_id: null,
        password: "",
    };

    const perfiles = [
        { idPerfil: 1, nombre: "ADMINISTRADOR" },
        { idPerfil: 2, nombre: "DENTISTA" },
    ];

    const [globalFilter, setGlobalFilter] = useState(null);
    const [usuarios, setUsuarios] = useState([]);
    const [usuario, setUsuario] = useState(usuarioVacio);
    const [recintos, setRecintos] = useState([]);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loadingUsuarios, setLoadingUsuarios] = useState(true);
    const [loadingGuardarUsuario, setLoadingGuardarUsuario] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        usuarioService.obtenerUsuarios().then(({ data, status }) => {
            if (status >= 200 && status < 300) {
                setUsuarios(data);
            } else {
                status !== 403 &&
                    data.errors.forEach((element) => {
                        toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                        return;
                    });
            }
            setLoadingUsuarios(false);
        });
        const recintoService = new RecintoService();
        recintoService.obtenerRecintos().then(({ data, status }) => {
            if (status >= 200 && status < 300) {
                setRecintos(data);
            } else {
                status !== 403 &&
                    data.errors.forEach((element) => {
                        toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                        return;
                    });
            }
        });
    }, []);

    const findIndexByRut = (rut) => {
        let index = -1;
        for (let i = 0; i < usuarios.length; i++) {
            if (usuarios[i].rut === rut) {
                index = i;
                break;
            }
        }

        return index;
    };

    const perfilBodytemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Perfil</span>
                <span className={`product-badge status-lowstock`}>{rowData.perfil.nombre}</span>
            </>
        );
    };

    const onPerfilChange = (e) => {
        let _usuario = { ...usuario };
        _usuario.perfil_id = e.value;
        _usuario.perfil.idperfil = e.value;
        _usuario.perfil.nombre = e.target.name;
        if (_usuario.perfil_id === 1) {
            _usuario = { ..._usuario, recinto_id: null, recinto: { idRecinto: null, nombre: null } };
        }
        setUsuario(_usuario);
    };

    const cambiarEstado = (rut) => {
        setLoadingGuardarUsuario(true);
        usuarioService.cambiarEstadoUsuario({ rut }).then(({ data, status }) => {
            if (status >= 200 && status < 300) {
                let _usuarios = [...usuarios];
                const _index = findIndexByRut(rut);
                let _usuario = { ..._usuarios[_index] };
                _usuario = { ..._usuario, activo: !_usuario.activo };
                _usuarios[_index] = _usuario;
                setUsuarios(_usuarios);
                toast.current.show({ severity: "success", summary: "Éxito", detail: data.msg, life: 3000 });
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingGuardarUsuario(false);
        });
    };

    const estadoBodyTemplate = ({ activo, rut, idUsuario }) => {
        let text = "";
        activo ? (text = "Desactivar cuenta") : (text = "Activar cuenta");
        return (
            <>
                <span className="p-column-title">Estado</span>
                {idUsuario !== 8 && <InputSwitch checked={activo} tooltip={text} onChange={() => cambiarEstado(rut)} />}
            </>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de usuarios</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const ocultarDialogUsuario = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    };

    const openNuevo = () => {
        setUsuario(usuarioVacio);
        setSubmitted(false);
        setUsuarioDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNuevo} />
            </React.Fragment>
        );
    };

    const guardarUsuario = () => {
        setSubmitted(true);
        if (!usuario.nombre || !usuario.rut || !usuario.password || !usuario.perfil_id) {
            return;
        }

        if (usuario.perfil_id === 2 && !usuario.recinto_id) {
            return;
        }

        setLoadingGuardarUsuario(true);
        usuarioService.guardarNuevoUsuario(usuario).then(({ data, status }) => {
            setLoadingGuardarUsuario(false);
            if (status >= 200 && status < 300) {
                toast.current.show({ severity: "success", summary: "Éxito", detail: "El nuevo usuario se ha registrado con éxito en el sistema", life: 3000 });
                setUsuarios([...usuarios, { ...data.usuario, perfil: { ...usuario.perfil }, perfil_id: usuario.perfil_id }]);
                setUsuarioDialog(false);
                setSubmitted(false);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                });
                return;
            }
        });
    };

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={ocultarDialogUsuario} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={guardarUsuario} />
        </>
    );

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;

        setUsuario(_usuario);
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={usuarios}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Usuarios"
                        globalFilter={globalFilter}
                        emptyMessage="Sin usuarios."
                        loading={loadingUsuarios}
                        header={header}
                    >
                        <Column field="rut" header="Rut" sortable></Column>
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="perfil.nombre" header="Perfil" body={perfilBodytemplate} sortable></Column>
                        <Column field="activo" header="Estado" body={estadoBodyTemplate} sortable></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingGuardarUsuario} />

                    <Dialog visible={usuarioDialog} style={{ width: "450px" }} header="Crear un nuevo usuario" modal className="p-fluid" footer={usuarioDialogFooter} onHide={ocultarDialogUsuario}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={usuario.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !usuario.nombre })} />
                            {submitted && !usuario.nombre && <small className="p-invalid red">El nombre es requerido.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="rut">Rut</label>
                            <InputText id="rut" value={usuario.rut} onChange={(e) => onInputChange(e, "rut")} required className={classNames({ "p-invalid": submitted && !usuario.rut })} />
                            {submitted && !usuario.rut && <small className="p-invalid">El rut es requerido.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="password">Contraseña</label>
                            <Password id="password" feedback={false} toggleMask value={usuario.password} onChange={(e) => onInputChange(e, "password")} required className={classNames({ "p-invalid": submitted && !usuario.password })} />
                            {submitted && !usuario.password && <small className="p-invalid">La contraseña es requerida.</small>}
                        </div>

                        <div className="p-field">
                            <label className="p-mb-3">Perfiles</label>
                            <div className="p-formgrid p-grid">
                                {perfiles.map((perfil) => {
                                    return (
                                        <div key={perfil.idPerfil} className="p-field-radiobutton p-col-6">
                                            <RadioButton inputId={perfil.idPerfil} name={perfil.nombre} value={perfil.idPerfil} onChange={onPerfilChange} checked={usuario.perfil_id === perfil.idPerfil} />
                                            <label htmlFor={perfil.idPerfil}>{perfil.nombre}</label>
                                        </div>
                                    );
                                })}
                            </div>
                            {submitted && !usuario.perfil_id && <small className="p-invalid">El perfil es requerido.</small>}
                        </div>

                        {usuario.perfil_id === 2 && (
                            <div className="p-field">
                                <label className="p-mb-3">Recintos</label>
                                <div className="p-formgrid p-grid">
                                    {recintos.map((recinto) => {
                                        return (
                                            <div key={recinto.idRecinto} className="p-field-radiobutton p-col-6">
                                                <RadioButton
                                                    inputId={recinto.idRecinto}
                                                    name="recinto"
                                                    value={recinto.idRecinto}
                                                    onChange={(e) => setUsuario({ ...usuario, recinto_id: e.value, recinto: { idRecinto: e.value, nombre: recinto.nombre } })}
                                                    checked={usuario.recinto_id === recinto.idRecinto}
                                                />
                                                <label htmlFor={recinto.idRecinto}>{recinto.nombre}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                                {submitted && !usuario.recinto_id && <small className="p-invalid">El recinto es requerido para este perfil de usuario.</small>}
                            </div>
                        )}
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
