import axios from "axios";

export class GrupoService {
    async obtenerGrupos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/grupo/obtener-grupos`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async guardarGrupo(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/grupo/`, {...data, date: new Date()})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async actualizarGrupo(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/grupo/cambiar-nombre/${data.idGrupo}`, {...data, date: new Date()})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
}
