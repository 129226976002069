import React, { useEffect, useRef, useState } from "react";
import { RecepcionSevice } from "../service/RecepcionService";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DialogProcesando } from "../components/DialogProcesando";
import { Dialog } from "primereact/dialog";

const recepcionService = new RecepcionSevice();

const recepcionVacia = {
    idRecepcion: null,
    codigo_recepcion: "",
    fecha: null,
    factura: "",
    usuario: {
        rut: "",
        nombre: "",
    },
};

export const Recepciones = () => {
    const [recepciones, setRecepciones] = useState([]);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterDetalle, setGlobalFilterDetalle] = useState(null);
    const [loadingRecepciones, setLoadingRecepciones] = useState(true);
    const [loadingCargando, setLoadingCargando] = useState(false);
    const [lotes, setLotes] = useState([]);
    const [recepcion, setRecepcion] = useState(recepcionVacia);
    const [detalleDialog, setDetalleDialog] = useState(false);
    const dt = useRef(null);

    useEffect(() => {
        recepcionService.obtenerRecepciones().then(({ status, data }) => {
            if (status >= 200 && status < 300) {
                setRecepciones(data);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingRecepciones(false);
        });
    }, []);

    const fechaBodyTemplate = ({ fecha }) => {
        let _fecha = new Date(fecha);
        return (
            <>
                <span className="p-column-title">Fecha de recepcion</span>
                {_fecha.toLocaleDateString('es-CL') + " " + _fecha.toLocaleTimeString('es-CL')}
            </>
        );
    };

    const fecha = (fecha) => {
        let _fecha = new Date(fecha);
        return _fecha.toLocaleDateString('es-CL') + " " + _fecha.toLocaleTimeString('es-CL');
    };
    const fechaVencimientoBodyTemplate = ({ lote }) => {
        let _fecha = new Date(lote.fecha_vencimiento);
        return (
            <>
                <span className="p-column-title">Fecha de Vencimiento</span>
                {_fecha.toLocaleDateString('es-CL')}
            </>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de recepciones</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar recepcion..." />
            </span>
        </div>
    );

    const headerDetalle = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de lotes.</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilterDetalle(e.target.value)} placeholder="Buscar lote..." />
            </span>
        </div>
    );

    const detallesRecepcion = (rowData) => {
        setLoadingCargando(true);
        recepcionService.verDetallesDeRecepcion(rowData).then(({ status, data }) => {
            const { lotes, recepcion } = data;
            setLoadingCargando(false);
            if (status >= 200 && status < 300) {
                setLotes(lotes);
                setRecepcion(recepcion);
                setGlobalFilterDetalle(null);
                setDetalleDialog(true);
            }
        });
    };

    const ocultarDialogDetalle = () => {
        setDetalleDialog(false);
    };

    const detallesBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-list" tooltip="Ver Detalles" className="p-button-rounded p-button-success p-mr-2" onClick={() => detallesRecepcion(rowData)} />
            </div>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" /* left={leftToolbarTemplate} */></Toolbar>

                    <DataTable
                        ref={dt}
                        value={recepciones}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Recepciones"
                        globalFilter={globalFilter}
                        emptyMessage="Sin recepciones."
                        loading={loadingRecepciones}
                        header={header}
                    >
                        <Column field="codigo_recepcion" header="Codigo Recepción" sortable></Column>
                        <Column field="factura" header="Factura o Guia de Despacho" sortable></Column>
                        <Column field="usuario.rut" header="Rut Responsable" sortable></Column>
                        <Column field="usuario.nombre" header="Nombre Responsable" sortable></Column>
                        <Column field="fecha" body={fechaBodyTemplate} header="Fecha" sortable></Column>
                        <Column body={detallesBodyTemplate} header="Detalles" sortable></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingCargando} />

                    <Dialog visible={detalleDialog} style={{ width: "1000px" }} header={`Detalles recepcion: ${recepcion.codigo_recepcion}`} modal className="p-fluid" onHide={ocultarDialogDetalle}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre Responsable</label>
                            <InputText id="nombre" disabled value={recepcion.usuario.nombre} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="rut">Rut Responsable</label>
                            <InputText id="rut" disabled value={recepcion.usuario.rut} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="fecha">Fecha</label>
                            <InputText id="fecha" disabled value={fecha(recepcion.fecha)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="factura">Factura</label>
                            <InputText id="factura" disabled value={recepcion.factura} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="codigo_recepcion">Codigo Recepción</label>
                            <InputText id="codigo_recepcion" disabled value={recepcion.codigo_recepcion} />
                        </div>

                        <DataTable
                            ref={dt}
                            value={lotes}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} lotes"
                            globalFilter={globalFilterDetalle}
                            emptyMessage="Sin lotes."
                            header={headerDetalle}
                        >
                            <Column field="lote.codigo_lote" header="Codigo Lote" sortable></Column>
                            <Column field="lote.insumo.nombre" header="Nombre" sortable></Column>
                            <Column field="cantidad" header="Cantidad Recepcionada" sortable></Column>
                            <Column field="lote.fecha_vencimiento" body={fechaVencimientoBodyTemplate} header="Fecha Vencimiento" sortable></Column>
                            <Column field="lote.insumo.grupo.nombre" header="Grupo" sortable></Column>
                        </DataTable>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
