import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classNames from "classnames";
import { DialogProcesando } from "../components/DialogProcesando";
import { GrupoService } from "../service/GrupoService";
import { InsumoService } from "../service/InsumoService";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

const insumoService = new InsumoService();

export const Insumo = () => {
    const insumoVacio = {
        idInsumo: null,
        nombre: "",
        codigo_insumo: "",
        activo: 1,
        grupo_id: null,
        grupo: {
            idGrupo: null,
            nombre: "",
        },
        cantidad: 0,
        cantidad_minima: 0,
    };
    const [insumos, setInsumos] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingInsumos, setLoadingInsumos] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [insumoDialog, setInsumoDialog] = useState(false);
    const [loadingGuardarInsumo, setLoadingGuardarInsumo] = useState(false);
    const [insumoEditarDialog, setInsumoEditarDialog] = useState(false);
    const [insumo, setInsumo] = useState(insumoVacio);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        insumoService.obtenerInsumos().then(({ status, data }) => {
            if ((status >= 200) & (status < 300)) {
                setInsumos(data);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingInsumos(false);
        });
        const grupoService = new GrupoService();
        grupoService.obtenerGrupos().then(({ status, data }) => {
            if ((status >= 200) & (status < 300)) {
                setGrupos(data);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
        });
    }, []);

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de insumos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar insumo..." />
            </span>
        </div>
    );

    const openNuevo = () => {
        setInsumoDialog(true);
        setSubmitted(false);
        setInsumo(insumoVacio);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNuevo} />
            </React.Fragment>
        );
    };
    const ocultarDialogInsumo = () => {
        setInsumoDialog(false);
        setInsumo(insumoVacio);
        setSubmitted(false);
    };
    const ocultarDialogEditarInsumo = () => {
        setInsumoEditarDialog(false);
        setInsumo(insumoVacio);
        setSubmitted(false);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _insumo = { ...insumo };
        _insumo[`${name}`] = val;

        setInsumo(_insumo);
    };

    const guardarInsumo = () => {
        setSubmitted(true);
        if (!insumo.nombre || !insumo.grupo_id) {
            return;
        }
        setLoadingGuardarInsumo(true);
        insumoService.guardarinsumo(insumo).then(({ status, data }) => {
            setLoadingGuardarInsumo(false);
            if (status >= 200 && status < 300) {
                setInsumos([...insumos, { ...data, grupo: { ...insumo.grupo } }]);
                toast.current.show({ severity: "success", summary: "Éxito", detail: `Insumo registrado de forma correcta`, life: 5000 });
                setSubmitted(false);
                setInsumo(insumoVacio);
                setInsumoDialog(false);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 5000 });
                });
                return;
            }
        });
    };

    const actualizarInsumo = () => {
        setSubmitted(true);
        if (!insumo.nombre || !insumo.grupo_id) {
            return;
        }
        setLoadingGuardarInsumo(true);
        let _insumo = insumos.find((data) => data.idInsumo === insumo.idInsumo);
        let insumoReal = { ...insumoVacio };
        if (_insumo.nombre !== insumo.nombre) {
            insumoReal.nombre = insumo.nombre;
        }

        if (_insumo.codigo_insumo !== insumo.codigo_insumo) {
            insumoReal.codigo_insumo = insumo.codigo_insumo;
        }

        insumoReal.cantidad = insumo.cantidad;
        insumoReal.cantidad_minima = insumo.cantidad_minima;
        insumoReal.grupo_id = insumo.grupo_id;
        insumoReal.idInsumo = insumo.idInsumo;

        insumoService.actualizarInsumo(insumoReal).then(({ status, data }) => {
            setLoadingGuardarInsumo(false);
            if (status >= 200 && status < 300) {
                let _insumos = [...insumos];
                const _index = findIndexById(insumo.idInsumo);
                _insumos[_index] = insumo;
                setInsumos(_insumos);
                toast.current.show({ severity: "success", summary: "Éxito", detail: `Insumo actualizado correctamente`, life: 3000 });
                setInsumoEditarDialog(false);
                setInsumo(insumoVacio);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 5000 });
                });
                return;
            }
        });
    };

    const insumoDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={ocultarDialogInsumo} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-outlined" onClick={guardarInsumo} />
        </>
    );
    const insumoEditarDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={ocultarDialogEditarInsumo} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-outlined" onClick={actualizarInsumo} />
        </>
    );

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _insumo = { ...insumo };
        _insumo[`${name}`] = val;

        setInsumo(_insumo);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < insumos.length; i++) {
            if (insumos[i].idInsumo === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const cambiarEstado = (id) => {
        setLoadingGuardarInsumo(true);
        insumoService.actualizarEstado(id).then(({ data, status }) => {
            if (status >= 200 && status < 300) {
                let _insumos = [...insumos];
                const _index = findIndexById(id);
                let _insumo = { ..._insumos[_index] };
                _insumo = { ..._insumo, activo: !_insumo.activo };
                _insumos[_index] = _insumo;
                setInsumos(_insumos);
                toast.current.show({ severity: "success", summary: "Éxito", detail: data.msg, life: 3000 });
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingGuardarInsumo(false);
        });
    };

    const estadoBodyTemplate = ({ idInsumo, activo }) => {
        let text = "";
        activo ? (text = "Desactivar Insumo") : (text = "Activar Insumo");
        return (
            <>
                <span className="p-column-title">Estado</span>
                <InputSwitch checked={activo} tooltip={text} onChange={() => cambiarEstado(idInsumo)} />
            </>
        );
    };

    const editarInsumo = (insumo) => {
        setInsumo({ ...insumo });
        setInsumoEditarDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editarInsumo(rowData)} />
            </div>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={insumos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Insumos"
                        globalFilter={globalFilter}
                        emptyMessage="Sin insumos."
                        loading={loadingInsumos}
                        header={header}
                    >
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="codigo_insumo" header="Codigo Insumo" sortable></Column>
                        <Column field="cantidad" header="Cantidad" sortable></Column>
                        <Column field="cantidad_minima" header="Cantidad Minima" sortable></Column>
                        <Column field="grupo.nombre" header="Grupo" sortable></Column>
                        <Column field="activo" header="Estado" body={estadoBodyTemplate} sortable></Column>
                        <Column header="Editar" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingGuardarInsumo} />

                    <Dialog visible={insumoDialog} style={{ width: "450px" }} header="Crear un nuevo insumo" modal className="p-fluid" footer={insumoDialogFooter} onHide={ocultarDialogInsumo}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={insumo.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !insumo.nombre })} />
                            {submitted && !insumo.nombre && <small className="p-error">El nombre es requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="grupo">Grupo</label>
                            <Dropdown value={insumo.grupo.nombre} options={grupos} onChange={(e) => setInsumo({ ...insumo, grupo: e.value, grupo_id: e.value.idGrupo })} optionLabel="nombre" editable />
                            {submitted && !insumo.nombre && <small className="p-error">El grupo es requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="codigo_insumo">Codigo Insumo</label>

                            <InputNumber id="codigo_insumo" value={insumo.nombcodigo_insumore} onValueChange={(e) => onInputNumberChange(e, "codigo_insumo")} integeronly />
                        </div>
                        <div className="p-field">
                            <label htmlFor="cantidad_minima">Cantidad Minima</label>
                            <InputNumber id="cantidad_minima" value={insumo.cantidad_minima} onValueChange={(e) => onInputNumberChange(e, "cantidad_minima")} integeronly />
                        </div>
                    </Dialog>

                    <Dialog visible={insumoEditarDialog} style={{ width: "450px" }} header={`Actualizar Insumo`} modal className="p-fluid" footer={insumoEditarDialogFooter} onHide={ocultarDialogInsumo}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={insumo.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !insumo.nombre })} />
                            {submitted && !insumo.nombre && <small className="p-error">El nombre es requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="codigo_insumo">Codigo Insumo</label>
                            <InputNumber id="codigo_insumo" value={insumo.nombcodigo_insumore} onValueChange={(e) => onInputNumberChange(e, "codigo_insumo")} integeronly />
                        </div>
                        <div className="p-field">
                            <label htmlFor="grupo">Grupo</label>
                            <Dropdown value={insumo.grupo.nombre} options={grupos} onChange={(e) => setInsumo({ ...insumo, grupo: e.value, grupo_id: e.value.idGrupo })} optionLabel="nombre" editable />
                            {submitted && !insumo.nombre && <small className="p-error">El grupo es requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="cantidad_minima">Cantidad Minima</label>
                            <InputNumber id="cantidad_minima" value={insumo.cantidad_minima} onValueChange={(e) => onInputNumberChange(e, "cantidad_minima")} integeronly />
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
