import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputNumber } from "primereact/inputnumber";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DialogProcesando } from '../components/DialogProcesando';
import { BajaService } from '../service/BajaService';
import { LoteService } from '../service/LoteService';

import classNames from "classnames";
import { UsuarioContext } from '../hooks/UsuarioContext';

const bajaService = new BajaService();

const bajaEmpty = {
    motivo: "",
    cantidad: null,
    fecha: null,
    codigo_lote: "",
    lote: {
        codigo_lote: "",
        fecha_vencimiento: "",
        insumo: {
            nombre: "",
            grupo: {
                nombre: ""
            }
        }
    }
}

export const Bajas = () => {

    const [bajas, setBajas] = useState([]);
    const [loadingBajas, setLoadingBajas] = useState(true);
    const [loadingLotes, setLoadingLotes] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterLote, setGlobalFilterLote] = useState(null);
    const [bajaLoading, setBajaLoading] = useState(false);
    const [lote, setLote] = useState(null);
    const [baja, setBaja] = useState(bajaEmpty);
    const [submitted, setSubmitted] = useState(false);
    const [bajaDialog, setBajaDialog] = useState(false);
    const [lotesDialog, setLotesDialog] = useState(false);
    const [lotes, setLotes] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);

    const { usuario } = useContext(UsuarioContext);

    useEffect(() => {
        bajaService.obtenerSalidas().then(({ status, data }) => {
            if (status >= 200 && status < 300) {
                setBajas(data);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingBajas(false);
        });
    }, [])

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _baja = { ...baja };
        _baja[`${name}`] = val;

        setBaja(_baja);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _baja = { ...baja };
        _baja[`${name}`] = val;

        setBaja(_baja);
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de bajas</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar baja..." />
            </span>
        </div>
    );

    const headerLotes = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de lotes</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilterLote(e.target.value)} placeholder="Buscar lote..." />
            </span>
        </div>
    );

    const fechaBodyTemplate = ({ fecha }) => {
        let _fecha = new Date(fecha);
        return (
            <>
                <span className="p-column-title">Fecha de bajada</span>
                {_fecha.toLocaleDateString('es-CL') + " " + _fecha.toLocaleTimeString('es-CL')}
            </>
        );
    };

    const openNuevo = () => {
        const loteService = new LoteService();
        loteService.allLotes().then(({ status, data }) => {
            setLotes(data);
        })
        setBaja(bajaEmpty);
        setSubmitted(false);
        setLoadingLotes(false);
        setLotesDialog(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNuevo} />
            </React.Fragment>
        );
    };

    const seleccionarLote = (rowData) => {
        setLote(rowData);
        setBajaDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" className="p-button-rounded p-button-success p-mr-2" onClick={() => seleccionarLote(rowData)} />
            </div>
        );
    };

    console.log(bajaLoading);

    const registrarBaja = () => {

        setSubmitted(true);
        if (!baja.motivo.length || !baja.cantidad < 1) {

            setBajaLoading(true);

            const dataService = {
                motivo: baja.motivo,
                cantidad: baja.cantidad,
                codigo_lote: lote.codigo_lote,
                usuario_id: usuario.usuario.idUsuario,
            }

            bajaService.create(dataService).then(({ status, data }) => {
                if (status >= 200 && status < 300) {
                    setBajaDialog(false);
                    setLotesDialog(false);
                    setBajas([{ ...data.baja, lote: { ...lote }, usuario: { rut: usuario.usuario.rut, nombre: usuario.usuario.nombre } }, ...bajas]);
                    setSubmitted(false);
                    toast.current.show({ severity: "success", summary: "Éxito", detail: data.msg, life: 3000 });
                } else {
                    data.errors.forEach((element) => {
                        toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                        return;
                    });
                }
                setBajaLoading(false);
            })

        } else {
            return;
        }
    }

    const bajaFooterDialog = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => setBajaDialog(false)} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={registrarBaja} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={bajas}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Bajas"
                        globalFilter={globalFilter}
                        emptyMessage="Sin bajas."
                        loading={loadingBajas}
                        header={header}
                    >
                        <Column field="codigo_baja" header="Codigo Baja" sortable></Column>
                        <Column field="motivo" header="Motivo" sortable></Column>
                        <Column field="cantidad" header="Cantidad" sortable></Column>
                        <Column field="lote.codigo_lote" header="Codigo Lote" sortable></Column>
                        <Column field="lote.insumo.nombre" header="Insumo" sortable></Column>
                        <Column field="lote.insumo.grupo.nombre" header="Grupo" sortable></Column>
                        <Column field="usuario.rut" header="Rut Responsable" sortable></Column>
                        <Column field="usuario.nombre" header="Nombre Responsable" sortable></Column>
                        <Column field="fecha" body={fechaBodyTemplate} header="Fecha" sortable></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingBajas} />
                    <DialogProcesando visible={bajaLoading} />

                    <Dialog visible={lotesDialog} style={{ width: "1000px" }} header="Lotes" modal className="p-fluid" onHide={() => setLotesDialog(false)}>
                        <DataTable
                            ref={dt}
                            value={lotes}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} lotes"
                            globalFilter={globalFilterLote}
                            emptyMessage="Sin lotes."
                            header={headerLotes}
                            loading={loadingLotes}
                        >
                            <Column field="codigo_lote" header="Codigo Lote" sortable></Column>
                            <Column field="insumo.nombre" header="Insumo" sortable></Column>
                            <Column field="insumo.grupo.nombre" header="Grupo" sortable></Column>
                            <Column field="fecha_vencimiento" header="Fecha" sortable></Column>
                            <Column field="cantidad" header="Cantidad" sortable></Column>
                            <Column header="Seleccionar Lote" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={bajaDialog} style={{ width: "1000px" }} header="Formulario de Baja" modal className="p-fluid" footer={bajaFooterDialog} onHide={() => setBajaDialog(false)}>
                        <div className="p-field">
                            <label htmlFor="motivo">Motivo</label>
                            <InputText id="motivo" value={baja.motivo} onChange={(e) => onInputChange(e, "motivo")} required autoFocus className={classNames({ "p-invalid": submitted && !baja.motivo })} />
                            {submitted && !baja.motivo && <small className="p-invalid red">El motivo es requerido.</small>}
                        </div>

                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <InputNumber id="cantidad" feedback={false} toggleMask value={baja.cantidad} onValueChange={(e) => onInputNumberChange(e, "cantidad")} required className={classNames({ "p-invalid": submitted && !baja.cantidad })} />
                            {submitted && !baja.cantidad && <small className="p-error">La cantidad es requerida.</small>}
                        </div>
                    </Dialog>



                </div>
            </div>
        </div>
    )
}
