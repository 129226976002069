import axios from "axios";

export class RecintoService {
    async obtenerRecintos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/recinto/obtener-recintos`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async recinto(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/recinto/`, {...data, date: new Date()})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
}
