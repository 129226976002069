import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { GrupoService } from "../service/GrupoService";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classNames from "classnames";
import { DialogProcesando } from "../components/DialogProcesando";

const grupoService = new GrupoService();

const grupoVacio = {
    idGrupo: null,
    nombre: "",
};

export const Grupo = () => {
    const [grupos, setGrupos] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingGrupos, setLoadingGrupos] = useState(true);
    const [grupo, setGrupo] = useState(grupoVacio);
    const [submitted, setSubmitted] = useState(false);
    const [grupoDialog, setGrupoDialog] = useState(false);
    const [grupoEditarDialog, setGrupoEditarDialog] = useState(false);
    const [loadingGuardarGrupo, setLoadingGuardarGrupo] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        grupoService.obtenerGrupos().then(({ status, data }) => {
            if (status >= 200 && status < 300) {
                setGrupos(data);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingGrupos(false);
        });
    }, []);

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de grupos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar grupo..." />
            </span>
        </div>
    );

    const openNuevo = () => {
        setGrupoDialog(true);
        setSubmitted(false);
        setGrupo(grupoVacio);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNuevo} />
            </React.Fragment>
        );
    };

    const ocultarDialogGrupo = () => {
        setGrupoDialog(false);
        setGrupo(grupoVacio);
        setSubmitted(false);
    };

    const ocultarActualizarDialogGrupo = () => {
        setGrupoEditarDialog(false);
        setGrupo(grupoVacio);
        setSubmitted(false);
    };

    const guardarGrupo = () => {
        setSubmitted(true);
        if (!grupo.nombre) {
            return;
        }
        setLoadingGuardarGrupo(true);
        grupoService.guardarGrupo(grupo).then(({ status, data }) => {
            setLoadingGuardarGrupo(false);
            if (status >= 200 && status < 300) {
                setGrupos([...grupos, data]);
                toast.current.show({ severity: "success", summary: "Éxito", detail: `Grupo registrado de forma correcta`, life: 5000 });
                setSubmitted(false);
                setGrupo(grupoVacio);
                setGrupoDialog(false);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 5000 });
                });
                return;
            }
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < grupos.length; i++) {
            if (grupos[i].idGrupo === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const actualizarGrupo = () => {
        setSubmitted(true);
        if (!grupo.nombre) {
            return;
        }
        setLoadingGuardarGrupo(true);
        grupoService.actualizarGrupo(grupo).then(({ status, data }) => {
            setLoadingGuardarGrupo(false);
            if (status >= 200 && status < 300) {
                let _grupos = [...grupos];
                const _index = findIndexById(grupo.idGrupo);
                let _grupo = { ..._grupos[_index] };
                _grupo = { ..._grupo, nombre: grupo.nombre };
                _grupos[_index] = _grupo;
                setGrupos(_grupos);
                toast.current.show({ severity: "success", summary: "Éxito", detail: data.msg, life: 3000 });
                setSubmitted(false);
                setGrupo(grupoVacio);
                setGrupoEditarDialog(false);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 5000 });
                });
                return;
            }
        });
    };

    const grupoDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={ocultarDialogGrupo} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-outlined" onClick={guardarGrupo} />
        </>
    );

    const grupoActualizarDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger p-button-text" onClick={ocultarActualizarDialogGrupo} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-outlined" onClick={actualizarGrupo} />
        </>
    );

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _grupo = { ...grupo };
        _grupo[`${name}`] = val;

        setGrupo(_grupo);
    };

    const editarInsumo = (grupo) => {
        setGrupo({ ...grupo });
        setGrupoEditarDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editarInsumo(rowData)} />
            </div>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={grupos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Grupos"
                        globalFilter={globalFilter}
                        emptyMessage="Sin grupos."
                        loading={loadingGrupos}
                        header={header}
                    >
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column header="Editar" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingGuardarGrupo} />

                    <Dialog visible={grupoDialog} style={{ width: "450px" }} header="Crear un nuevo grupo de insumos" modal className="p-fluid" footer={grupoDialogFooter} onHide={ocultarDialogGrupo}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={grupo.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !grupo.nombre })} />
                            {submitted && !grupo.nombre && <small className="p-error">El nombre es requerido.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={grupoEditarDialog} style={{ width: "450px" }} header="Actualizar nombre del grupo" modal className="p-fluid" footer={grupoActualizarDialogFooter} onHide={ocultarActualizarDialogGrupo}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={grupo.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !grupo.nombre })} />
                            {submitted && !grupo.nombre && <small className="p-error">El nombre es requerido.</small>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
