import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import { Route, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppProfile } from "./AppProfile";
/* import { AppConfig } from "./AppConfig"; */

import { Dashboard } from "./components/Dashboard";

import PrimeReact from "primereact/api";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { Usuario } from "./pages/Usuario";
import { Grupo } from "./pages/Grupo";
import { Insumo } from "./pages/Insumo";
import { Recepciones } from "./pages/Recepciones";
import { Recepcion } from "./pages/Recepcion";
import { Solicitudes } from "./pages/Solicitudes";
import { Solicitud } from "./pages/Solicitud";
import { Reparto } from "./pages/Reparto";
import { Repartos } from "./pages/Repartos";
import { Bajas } from "./pages/Bajas";
import { Recinto } from "./pages/Recinto";
import { UsuarioContext } from "./hooks/UsuarioContext";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("dark");
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(false);
    const sidebar = useRef();

    const history = useHistory();

    const { usuario } = useContext(UsuarioContext);

    let menuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                setOverlayMenuActive((prevState) => !prevState);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };

    let menu;

    if (usuario.usuario.perfil_id === 1) {
        menu = [
            { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" },
            { label: "Usuario", icon: "pi pi-fw pi-users", to: "/usuarios" },
            { label: "Grupo", icon: "pi pi-fw pi-clone", to: "/grupos" },
            { label: "Insumo", icon: "pi pi-fw pi-comments", to: "/insumos" },
            { label: "Recepciones", icon: "pi pi-fw pi-folder-open", to: "/recepciones" },
            { label: "Recepción", icon: "pi pi-fw pi-folder-open", to: "/recepcion" },
            { label: "Recinto", icon: "pi pi-fw pi-folder-open", to: "/recintos" },
            { label: "Solicitud", icon: "pi pi-fw pi-chevron-right", to: "/solicitud" },
            { label: "Solicitudes", icon: "pi pi-fw pi-angle-double-right", to: "/solicitudes" },
            { label: "Repartos", icon: "pi pi-fw pi-shopping-cart", to: "/repartos" },
            { label: "Bajas", icon: "pi pi-fw pi-angle-double-down", to: "/bajas" },
        ];
    } else {
        menu = [
            { label: "Solicitud", icon: "pi pi-fw pi-chevron-right", to: "/solicitud" },
        ];
    }

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === "static") return !staticMenuInactive;
            else if (layoutMode === "overlay") return overlayMenuActive;
            else return true;
        }

        return true;
    };

    const logo = layoutColorMode === "dark" ? "assets/layout/images/logo-white.svg" : "assets/layout/images/logo.svg";

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
    });

    const sidebarClassName = classNames("layout-sidebar", {
        "layout-sidebar-dark": layoutColorMode === "dark",
        "layout-sidebar-light": layoutColorMode === "light",
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenu={onToggleMenu} />

            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    {/* <div className="layout-logo" style={{ cursor: "pointer" }} onClick={() => history.push("/")}>
                        <img alt="Logo" src={logo} />
                    </div> */}
                    <AppProfile />
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                </div>
            </CSSTransition>

            {/* <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} /> */}

            <div className="layout-main">
                <Route path="/" exact component={Dashboard} />
                <Route path="/usuarios" component={Usuario} />
                <Route path="/grupos" component={Grupo} />
                <Route path="/insumos" component={Insumo} />
                <Route path="/recepciones" component={Recepciones} />
                <Route path="/recepcion" component={Recepcion} />
                <Route path="/solicitudes" component={Solicitudes} />
                <Route path="/solicitud" component={Solicitud} />
                <Route path="/recintos" component={Recinto} />
                <Route path="/reparto/:id" component={Reparto} />
                <Route path="/repartos" component={Repartos} />
                <Route path="/bajas" component={Bajas} />
            </div>

            <AppFooter />
        </div>
    );
};

export default App;
