import axios from "axios";

export class InsumoService {
    async obtenerInsumos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/insumo/insumos`)
            .then((response) => response)
            .catch((error) => error.response);
    }
    async guardarinsumo(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/insumo`, {...data, date: new Date()})
            .then((response) => response)
            .catch((error) => error.response);
    }
    async actualizarEstado(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/insumo/cambiar-activo/${id}`)
            .then((response) => response)
            .catch((error) => error.response);
    }
    async actualizarInsumo(data) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/insumo/actualizar-insumo/${data.idInsumo}`, {...data, date: new Date()})
            .then((response) => response)
            .catch((error) => error.response);
    }
}
