import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { SolicitudService } from '../service/SolicitudService';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { InputNumber } from "primereact/inputnumber";
import { LoteService } from '../service/LoteService';
import { Despachoservice } from '../service/DespachoService';
import { DialogProcesando } from '../components/DialogProcesando';

export const Reparto = () => {
    const { id } = useParams();
    const [loadingSolicitud, setLoadingSolicitud] = useState(true);
    const [loadingReparto, setLoadingReparto] = useState(false);
    const [insumosSolicitud, setInsumosSolicitud] = useState([]);
    const [lotes, setLotes] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loteGlobalFilter, setLoteGlobalFilter] = useState(null);
    const [loteDialog, setLoteDialog] = useState(false);
    const [cantidadDialog, setCantidadDialog] = useState(false);
    const [loadingLotes, setLoadingLotes] = useState(true);
    const [cantidad, setCantidad] = useState(0);
    const [solicitud, setSolicitud] = useState(null);
    const [lote, setLote] = useState(null);
    const [insumo, setInsumo] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const dt = useRef(null);

    useEffect(() => {
        const solicitudService = new SolicitudService();
        solicitudService.obtenerSolicitud(id).then(({ status, data }) => {
            setLoadingSolicitud(false);
            if (status >= 200 && status < 300) {
                let _insumos = data.insumos.map((insumo) => {
                    return { ...insumo, lotes: [] };
                })
                setInsumosSolicitud(_insumos);
                setSolicitud(data.solicitud);
            }
        });
    }, [id]);

    if (redirect) {
        return <Redirect to={'/'} />
    }

    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        let _cantidad = cantidad;
        _cantidad = val;

        setCantidad(_cantidad);
    };

    const busquedaLote = (rowData) => {
        setInsumo(rowData);
        setLoteDialog(true);
        const loteServices = new LoteService();
        loteServices.obtenerLotesInsumo(rowData.insumo.idInsumo).then(({ status, data }) => {
            if (status >= 200 && status < 300) {
                setLotes(data);
                setLoteGlobalFilter(null);
            }
        })
        setLoadingLotes(false);
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de solicitudes.</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar solicitud..." />
            </span>
        </div>
    );

    const headerLote = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de lotes.</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setLoteGlobalFilter(e.target.value)} placeholder="Buscar lote..." />
            </span>
        </div>
    );

    const onRowExpand = (event) => {
        toast.current.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
    }

    const onRowCollapse = (event) => {
        toast.current.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
    }

    const finByIndex = (id) => {
        return insumosSolicitud.findIndex((insumo) => insumo.insumo.idInsumo === id);
    }

    const selecionarLote = (rowData) => {
        setCantidadDialog(true);
        setLote(rowData);
    }

    const quitarLote = (rowData) => {
        const index = finByIndex(rowData.insumo_id);

        let _insumo = insumosSolicitud[index];
        let _lotes = _insumo.lotes.filter((insumo) => insumo.codigo_lote !== rowData.codigo_lote);
        _insumo = { ..._insumo, lotes: [..._lotes] };

        let _insumosSolicitud = [...insumosSolicitud];
        _insumosSolicitud[index] = { ..._insumo };
        setInsumosSolicitud(_insumosSolicitud);
    }

    const guardarCantidad = () => {
        /* Se busca id del insumo */
        const index = finByIndex(insumo.insumo.idInsumo);
        let _insumosSolicitud = [...insumosSolicitud];
        let _insumo = { ..._insumosSolicitud[index] };
        let _lote = { ...lote, cantidadSeleccionada: cantidad };
        let _lotes = [..._insumo.lotes, { ..._lote }];
        _insumo = { ..._insumo, lotes: [..._lotes] };
        _insumosSolicitud[index] = { ..._insumo };
        setInsumosSolicitud(_insumosSolicitud);
        setCantidad(0);
        setCantidadDialog(false);
        setLoteDialog(false);
    }

    const guardarReparto = () => {
        setLoadingReparto(true);
        let _lotes = [];
        insumosSolicitud.forEach((insumo) => {
            insumo.lotes.forEach((lote) => {
                _lotes.push({
                    insumo_id: lote.insumo_id,
                    cantidad: lote.cantidadSeleccionada,
                    codigo_lote: lote.codigo_lote
                });
            })
        })
        const data = {
            solicitud_id: solicitud.idSolicitud,
            lotes: [..._lotes],
            recinto_id: solicitud.recinto_id,
        }

        const despachoService = new Despachoservice();
        despachoService.registrarDespacho(data).then(({ status, data }) => {
            if (status >= 200 && status < 300) {
                toast.current.show({ severity: "success", summary: "Éxito", detail: data.msg, life: 3000 });
                setInsumosSolicitud([]);
                setTimeout(() => {
                    setRedirect(true);
                }, 3000);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingReparto(false);
        });
    }

    const rigthToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Guardar reparto" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={guardarReparto} />
            </React.Fragment>
        );
    };

    const quitarLoteBodyTemplate = (rowData) => {
        return <div className="actions">
            <Button icon="pi pi-times" tooltip="Quitar Lote" className="p-button-rounded p-button-danger p-mr-2" onClick={e => quitarLote(rowData)} />
        </div>;
    };
    const seleccionarBodyTemplate = (rowData) => {
        return <div className="actions">
            <Button icon="pi pi-plus" tooltip="Seleccionar Insumo" className="p-button-rounded p-button-success p-mr-2" onClick={e => busquedaLote(rowData)} />
        </div>;
    };
    const seleccionarLoteBodyTemplate = (rowData) => {
        return <div className="actions">
            <Button icon="pi pi-plus" tooltip="Seleccionar Lote" className="p-button-rounded p-button-success p-mr-2" onClick={e => selecionarLote(rowData)} />
        </div>;
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Lotes seleccionados para {data.insumo.nombre}</h5>
                <DataTable value={data.lotes}>
                    <Column field="codigo_lote" header="Codigo lote" sortable></Column>
                    <Column field="cantidadSeleccionada" header="Cantidad Seleccionada" sortable></Column>
                    <Column field="fecha_vencimiento" header="Fecha Vencimiento" sortable></Column>
                    <Column header="Quitar" body={quitarLoteBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    }

    const cantidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => setCantidadDialog(false)} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={guardarCantidad} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" right={rigthToolbarTemplate}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={insumosSolicitud}
                        dataKey="insumo.idInsumo"
                        className="datatable-responsive"
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={onRowExpand}
                        onRowCollapse={onRowCollapse}
                        rowExpansionTemplate={rowExpansionTemplate}
                        globalFilter={globalFilter}
                        emptyMessage="Sin insumos."
                        header={header}
                        loading={loadingSolicitud}
                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column field="insumo.nombre" header="Nombre Insumo" sortable></Column>
                        <Column field="insumo.grupo.nombre" header="Grupo" sortable></Column>
                        <Column field="cantidad" header="Cantidad Solicitada" sortable></Column>
                        <Column header="Seleccionar" body={seleccionarBodyTemplate}></Column>
                    </DataTable>
                </div>

                <DialogProcesando visible={loadingReparto} />
                <Dialog visible={loteDialog} onHide={() => setLoteDialog(false)} style={{ width: '80%' }} >
                    <DataTable value={lotes} header={headerLote} globalFilter={loteGlobalFilter} emptyMessage="Sin lotes." loading={loadingLotes}>
                        <Column field="codigo_lote" header="Codigo Lote" sortable></Column>
                        <Column field="cantidad" header="Cantidad" sortable></Column>
                        <Column field="fecha_vencimiento" header="Fecha Vencimiento" sortable></Column>
                        <Column header="Seleccionar" body={seleccionarLoteBodyTemplate}></Column>
                    </DataTable>
                </Dialog>
                <Dialog visible={cantidadDialog} className="p-fluid" header="Cantidad Lote" footer={cantidadDialogFooter} onHide={() => setCantidadDialog(false)} style={{ width: "auto" }} >
                    <div className="p-field">
                        <label htmlFor="cantidad">Cantidad</label>
                        <InputNumber value={cantidad} onValueChange={(e) => onInputNumberChange(e)} />
                    </div>
                </Dialog>
            </div>
        </div>
    )
}
