import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { UsuarioContext } from './hooks/UsuarioContext';

export const AppProfile = () => {

    const [expanded, setExpanded] = useState(false);
    const { usuario } = useContext(UsuarioContext);

    const onClick = (event) => {
        setExpanded(prevState => !prevState);
        event.preventDefault();
    }

    return (
        <div className="layout-profile">
            <div>
                <img src="logo-muelas.png" alt="Profile" />
            </div>
            <div style={{ color: 'white' }}>
                {usuario.usuario.nombre}
            </div>
            {/*  <button className="p-link layout-profile-link" onClick={onClick}>
                <span className="username">{usuario.usuario.nombre}</span>
                <i className="pi pi-fw pi-cog" />
            </button> */}
            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <ul className={classNames({ 'layout-profile-expanded': expanded })}>
                    {/* <li><button type="button" className="p-link"><i className="pi pi-fw pi-user" /><span>Account</span></button></li>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-inbox" /><span>Notifications</span><span className="menuitem-badge">2</span></button></li>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li> */}
                </ul>
            </CSSTransition>
        </div>
    );

}
