import axios from 'axios';

export class Despachoservice {
    async registrarDespacho(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/despacho/`, {...data, date: new Date()})
            .then((response) => response)
            .catch((error) => error.response);
    }

    async despachos() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/despacho/despachos`)
            .then((response) => response)
            .catch((error) => error.response);
    }

    async despachosById(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/despacho/${id}`)
            .then((response) => response)
            .catch((error) => error.response);
    }
}
