import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DialogProcesando } from "../components/DialogProcesando";
import { Dialog } from "primereact/dialog";
import { InsumoService } from "../service/InsumoService";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";
import { SolicitudService } from "../service/SolicitudService";
import { RecintoService } from "../service/RecintoService";
import { Dropdown } from "primereact/dropdown";

const insumoVacio = {
    id: null,
    cantidad: null,
};

export const Solicitud = () => {
    const [insumos, setInsumos] = useState([]);
    const [insumosSolicitud, setInsumosSolicitud] = useState([]);
    const [insumo, setInsumo] = useState(insumoVacio);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterInsumos, setGlobalFilterInsumos] = useState(null);
    const [loadingCargando, setLoadingCargando] = useState(true);
    const [insumoDialog, setInsumoDialog] = useState(false);
    const [insumoCantidadDialog, setInsumoCantidadDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [quitarInsumoDialog, setQuitarInsumoDialog] = useState(false);
    const [insumoQuitar, setInsumoQuitar] = useState(null);
    const [recintos, setRecintos] = useState([]);
    const [recinto, setRecinto] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const recintoService = new RecintoService();
        recintoService.obtenerRecintos().then(({ data, status }) => {
            if (status >= 200 && status < 300) {
                setRecintos(data);
            } else {
                status !== 403 &&
                    data.errors.forEach((element) => {
                        toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    });
            }
            setLoadingCargando(false);
        });
    }, [])

    const guardarRecepcion = () => {
        setSubmitted(false);

        if (!insumosSolicitud.length) {
            toast.current.show({ severity: "error", summary: "Error", detail: "La solicitud no contiene insumos", life: 3000 });
            return;
        }

        if (!recinto) {
            toast.current.show({ severity: "error", summary: "Error", detail: "Debe seleccionar un recinto", life: 3000 });
            return;
        }

        setLoadingCargando(true);
        let _data = insumosSolicitud.map((insumo) => {
            return {
                id: insumo.idInsumo,
                cantidad: insumo.cantidad,
            };
        });

        const solicitudService = new SolicitudService();
        solicitudService.registrarSolicitud({ recinto_id: recinto.idRecinto, insumos_id: _data }).then(({ data, status }) => {
            setLoadingCargando(false);
            if (status >= 200 && status < 300) {
                setInsumosSolicitud([]);
                toast.current.show({ severity: "success", summary: "Éxito", detail: data.msg, life: 3000 });
            } else {
                data.errors?.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
        });
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de insumos agregados</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar insumo..." />
            </span>
        </div>
    );

    const headerInsumos = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de insumos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilterInsumos(e.target.value)} placeholder="Buscar insumo..." />
            </span>
        </div>
    );

    const openInsumos = () => {
        setLoadingCargando(true);
        const insumoService = new InsumoService();
        insumoService.obtenerInsumos().then(({ data, status }) => {
            setLoadingCargando(false);
            if (status >= 200 && status < 300) {
                setInsumos(data);
                setGlobalFilterInsumos(null);
                setInsumoDialog(true);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
        });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openInsumos} />
            </React.Fragment>
        );
    };

    const rigthToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Guardar solicitud" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={guardarRecepcion} />
            </React.Fragment>
        );
    };

    const ocultarDialogInsumos = () => {
        setInsumoDialog(false);
    };

    const agregarInsumo = (_insumo) => {
        setInsumo({ ..._insumo, cantidad: 0 });
        setInsumoCantidadDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" className="p-button-rounded p-button-success p-mr-2" onClick={() => agregarInsumo(rowData)} />
            </div>
        );
    };

    const quitarInsumo = (insumoQuitar) => {
        setLoadingCargando(true);
        let _insumosSolicitud = insumosSolicitud.filter((insumo) => insumo.idInsumo !== insumoQuitar.idInsumo);
        setInsumosSolicitud(_insumosSolicitud);
        setInsumos([...insumos, insumoQuitar]);
        setLoadingCargando(false);
        setQuitarInsumoDialog(false);
        toast.current.show({ severity: "success", summary: "Éxito", detail: "El insumo se ha quitado correctamente", life: 3000 });
    };

    const quitarLoteBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger p-mr-2"
                    onClick={() => {
                        setInsumoQuitar(rowData);
                        setQuitarInsumoDialog(true);
                    }}
                />
            </div>
        );
    };

    const ocultarDialogLote = () => {
        setInsumoCantidadDialog(false);
        setInsumo(insumoVacio);
        setSubmitted(false);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _insumo = { ...insumo };
        _insumo[`${name}`] = val;

        setInsumo(_insumo);
    };

    const registrarInsumo = () => {
        setLoadingCargando(true);
        setSubmitted(true);

        if (!insumo.cantidad) {
            setLoadingCargando(false);
            toast.current.show({ severity: "error", summary: "Error", detail: "Complete todos los campos solicitados", life: 3000 });
            return;
        }

        let _insumos = insumos.filter((data) => data.idInsumo !== insumo.idInsumo);
        setInsumosSolicitud([...insumosSolicitud, insumo]);
        setInsumos(_insumos);
        setInsumoCantidadDialog(false);
        setInsumoDialog(false);
        setInsumo(insumoVacio);

        setSubmitted(false);
        setLoadingCargando(false);
        toast.current.show({ severity: "success", summary: "Éxito", detail: "Insumo agregado con éxito", life: 3000 });
    };

    const loteFooterDialog = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={ocultarDialogLote} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={registrarInsumo} />
        </>
    );

    const hideQuitarInsumoDialog = () => {
        setQuitarInsumoDialog(false);
    };

    const quitarInsumoDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-secondary p-button-text" onClick={hideQuitarInsumoDialog} />
            <Button label="Quitar" icon="pi pi-check" className="p-button-outlined p-button-danger" onClick={() => quitarInsumo(insumoQuitar)} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rigthToolbarTemplate}></Toolbar>

                    <div className="p-field">
                        <label className="p-mb-4" htmlFor="recinto">Recinto:</label>
                        <br />
                        <Dropdown value={recinto?.nombre} options={recintos} onChange={(e) => setRecinto(e.value)} optionLabel="nombre" editable />
                        {submitted && !recinto && <small className="p-error">El Recinto es requerido.</small>}
                    </div>

                    <DataTable
                        ref={dt}
                        value={insumosSolicitud}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} lotes agregados"
                        globalFilter={globalFilter}
                        emptyMessage="Sin insumos agregados para la solicitud."
                        header={header}
                    >
                        <Column field="nombre" header="Nombre" sortable></Column>
                        <Column field="grupo.nombre" header="Grupo" sortable></Column>
                        <Column field="cantidad" header="Cantidad" sortable></Column>
                        <Column header="Quitar" body={quitarLoteBodyTemplate} sortable></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingCargando} />

                    <Dialog visible={insumoDialog} style={{ width: "1000px" }} header="Insumos" modal className="p-fluid" onHide={ocultarDialogInsumos}>
                        <DataTable
                            ref={dt}
                            value={insumos}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} insumos"
                            globalFilter={globalFilterInsumos}
                            emptyMessage="Sin insumos."
                            header={headerInsumos}
                        >
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="grupo.nombre" header="Grupo" sortable></Column>
                            <Column header="Seleccionar Insumo" body={actionBodyTemplate}></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={insumoCantidadDialog} style={{ width: "450px" }} header="Cantidad a solicitar" modal className="p-fluid" footer={loteFooterDialog} onHide={ocultarDialogLote}>
                        <div className="p-field">
                            <label htmlFor="cantidad">Cantidad</label>
                            <InputNumber id="cantidad" feedback={false} toggleMask value={insumo.cantidad} onValueChange={(e) => onInputNumberChange(e, "cantidad")} required className={classNames({ "p-invalid": submitted && !insumo.cantidad })} />
                            {submitted && !insumo.cantidad && <small className="p-error">La cantidad es requerida.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={quitarInsumoDialog} style={{ width: "450px" }} header="Confirmar" modal footer={quitarInsumoDialogFooter} onHide={hideQuitarInsumoDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            <span>¿Seguro que desea quitar el insumo: {insumoQuitar?.nombre}?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
