import axios from "axios";
export class SolicitudService {
    async obtenerSolicitudes() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/solicitud/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async obtenerSolicitud(id) {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/solicitud/obtener-solicitud/${id}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async registrarSolicitud(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/solicitud/`, {...data, date: new Date()})
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
}
