import React, { useEffect, useRef, useState } from 'react'
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DialogProcesando } from '../components/DialogProcesando'
import { Dialog } from 'primereact/dialog';
import { Despachoservice } from '../service/DespachoService';

const despachoService = new Despachoservice();

export const Repartos = () => {
    const [loadingCargando, setLoadingCargando] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterDetalle, setGlobalFilterDetalle] = useState(null);
    const [loadingRepartos, setLoadingRepartos] = useState(true);
    const [repartos, setRepartos] = useState([]);
    const [reparto, setReparto] = useState(null);
    const [lotesReparto, setLotesReparto] = useState([]);
    const [detalleDialog, setDetalleDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        despachoService.despachos().then(({ status, data }) => {
            setLoadingCargando(false);
            if (status >= 200 && status < 300) {
                setRepartos(data);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingRepartos(false);
        })
    }, [])

    const detallesReparto = (rowData) => {
        setLoadingCargando(true);
        despachoService.despachosById(rowData.idDespacho).then(({ status, data }) => {
            if (status >= 200 && status < 300) {
                const { despacho, lotes } = data;
                setReparto(despacho);
                console.log(despacho);
                setLotesReparto(lotes);
                setLoadingCargando(false);
                setDetalleDialog(true);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setLoadingCargando(false);
        })
    };

    const detallesBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-list" className="p-button-rounded p-button-success p-mr-2" onClick={() => detallesReparto(rowData)} />
            </div>
        );
    };

    const fechaBodyTemplate = ({ fecha }) => {
        let _fecha = new Date(fecha);
        return (
            <>
                <span className="p-column-title">Fecha de reparto</span>
                {_fecha.toLocaleDateString('es-CL') + " " + _fecha.toLocaleTimeString('es-CL')}
            </>
        );
    };

    const fecha = (fecha) => {
        let _fecha = new Date(fecha);
        return _fecha.toLocaleDateString('es-CL') + " " + _fecha.toLocaleTimeString('es-CL');
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de repartos.</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar reparto..." />
            </span>
        </div>
    );

    const headerDetalle = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de lotes.</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilterDetalle(e.target.value)} placeholder="Buscar lote..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" /* left={leftToolbarTemplate} */></Toolbar>

                    <DataTable
                        ref={dt}
                        value={repartos}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Repartos"
                        globalFilter={globalFilter}
                        emptyMessage="Sin repartos."
                        loading={loadingRepartos}
                        header={header}
                    >
                        <Column field="codigo_despacho" header="Codigo Despacho" sortable></Column>
                        <Column field="usuario.rut" header="Rut Responsable" sortable></Column>
                        <Column field="usuario.nombre" header="Nombre Responsable" sortable></Column>
                        <Column field="fecha" body={fechaBodyTemplate} header="Fecha" sortable></Column>
                        <Column field="recinto.nombre" header="Recinto Solicitante" sortable></Column>
                        <Column body={detallesBodyTemplate} header="Detalles" sortable></Column>
                    </DataTable>

                    <DialogProcesando visible={loadingCargando} />

                    <Dialog visible={detalleDialog} style={{ width: "1000px" }} modal className="p-fluid" onHide={e => { setDetalleDialog(false) }} >
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre Responsable</label>
                            <InputText id="nombre" disabled value={reparto?.usuario.nombre} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="rut">Rut Responsable</label>
                            <InputText id="rut" disabled value={reparto?.usuario.rut} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="fecha">Fecha</label>
                            <InputText id="fecha" disabled value={fecha(reparto?.fecha)} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="codigo_despacho">Codigo Despacho</label>
                            <InputText id="codigo_despacho" disabled value={reparto?.codigo_despacho} />
                        </div>
                        <div className="p-field">
                            <label htmlFor="recinto">Recinto</label>
                            <InputText id="recinto" disabled value={reparto?.recinto.nombre} />
                        </div>

                        <DataTable
                            ref={dt}
                            value={lotesReparto}
                            dataKey="id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} lotes"
                            globalFilter={globalFilterDetalle}
                            emptyMessage="Sin lotes."
                            header={headerDetalle}
                        >
                            <Column field="lote.codigo_lote" header="Codigo Lote" sortable></Column>
                            <Column field="lote.insumo.nombre" header="Nombre Insumo" sortable></Column>
                            <Column field="lote.insumo.grupo.nombre" header="Nombre Grupo" sortable></Column>
                            <Column field="cantidad" header="Cantidad Despachada" sortable></Column>
                        </DataTable>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}
