import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from "primereact/datatable";
import { Chart } from 'primereact/chart';
import { TemperaturaService } from '../service/TemperaturaService';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { DialogProcesando } from './DialogProcesando';
import { UsuarioContext } from '../hooks/UsuarioContext';
import { LoteService } from '../service/LoteService';
import { InsumoService } from '../service/InsumoService';
import { Redirect } from 'react-router-dom';


const temperaturaService = new TemperaturaService();
const loteService = new LoteService();
const insumoService = new InsumoService();

export const Dashboard = () => {

    const { usuario } = useContext(UsuarioContext);
    const [dias, setDias] = useState([]);
    const [temperaturas, setTemperaturas] = useState([]);
    const [vencidos, setVencidos] = useState([]);
    const [proximosVencer, setProximosVencer] = useState([]);
    const [quiebresStock, setQuiebresStock] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilterProximosVencer, setGlobalFilterProximosVencer] = useState(null);
    const [globalFilterInsumo, setGlobalFilterInsumo] = useState(null);
    const [loadingVencidos, setLoadingVencidos] = useState(true);
    const [loadingInsumos, setLoadingInsumos] = useState(true);
    const [loadingProximosVencer, setLoadingProximosVencer] = useState(true);
    const [productDialog, setProductDialog] = useState(false);
    const [temperatura, setTemperatura] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    const trabajarTemperaturas = () => {
        temperaturaService.obtenerTemperaturas().then(({ status, data }) => {
            let _dias = [];
            let _temperaturas = [];
            data.forEach(temperatura => {
                let _fecha = new Date(temperatura.fecha);
                _temperaturas = [..._temperaturas, temperatura.grados];
                _dias = [..._dias, _fecha.toLocaleDateString('es-CL')];
            });
            setDias(_dias);
            setTemperaturas(_temperaturas);
        })
    }

    const getVencidos = () => {
        loteService.vencidos().then(({ status, data }) => {
            setVencidos(data);
            setLoadingVencidos(false);
        })
    }

    const getProximosVencer = () => {
        loteService.proximosVencer().then(({ status, data }) => {
            setProximosVencer(data);
            setLoadingProximosVencer(false);
        })
    }

    const getInsumosQuiebreStock = () => {
        insumoService.obtenerInsumos().then(({ status, data }) => {
            const _data = data.filter((value) => value.cantidad_minima >= value.cantidad);
            setQuiebresStock(_data);
            setLoadingInsumos(false);
        })
    }

    useEffect(() => {
        trabajarTemperaturas();
        getVencidos();
        getProximosVencer();
        getInsumosQuiebreStock();
    }, []);

    const hideDialog = () => {
        setProductDialog(false);
    };

    const lineData = {
        labels: dias,
        datasets: [
            {
                label: "Temperatura",
                data: temperaturas,
                fill: false,
                backgroundColor: "#008F39",
                borderColor: "#008F39",
            },
        ],
    };

    const click = () => {
        setProductDialog(true);
    };

    const onInputNumberChange = (e) => {
        const val = e.value || 0;
        let _temperatura = temperatura;
        _temperatura = val;

        setTemperatura(_temperatura);
    };

    const saveProduct = async () => {
        setSubmitted(true);

        if (!temperatura) {
            return;
        }
        setLoading(true);

        temperaturaService.create({ grados: temperatura }).then(({ status, data }) => {
            if (status >= 200 && status < 300) {
                toast.current.show({ severity: "success", summary: "Éxito", detail: data.msg, life: 3000 });
                setTemperaturas([...temperaturas, data.temperatura.grados]);
                let _fecha = new Date(data.temperatura.fecha);
                setDias([...dias, _fecha.toLocaleDateString('es-CL')]);
            } else {
                data.errors.forEach((element) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: element.msg, life: 3000 });
                    return;
                });
            }
            setProductDialog(false);
            setTemperatura(0);
            setLoading(false);
        })

    };

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const headerVencidos = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de Lotes Vencidos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar lote..." />
            </span>
        </div>
    );
    const headerProximosVencer = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de Lotes Proximos a Vencer</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilterProximosVencer(e.target.value)} placeholder="Buscar lote..." />
            </span>
        </div>
    );
    const headerQuiebreStockr = (
        <div className="table-header">
            <h5 className="p-m-0">Listado de Insumos con Quiebre de Stock</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilterInsumo(e.target.value)} placeholder="Buscar Insumo..." />
            </span>
        </div>
    );

    const fecha = ({ fecha_vencimiento }) => {
        let _fecha = new Date(fecha_vencimiento);
        return _fecha.toLocaleDateString('es-CL');
    };

    return (
        <div className="p-grid p-fluid dashboard">
            <Toast ref={toast} />
            {
                usuario.usuario.perfil_id === 1 ? (
                    <>
                        <div className="p-col-12 p-lg-12">
                            <div className="card">
                                <Button onClick={click} tooltip="Registrar Temperatura" label="Registrar Nueva Temperatura" className="p-button-outlined p-mr-2" />
                                <Chart type="line" data={lineData} />
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-12">
                            <div className="card">
                                <DataTable
                                    ref={dt}
                                    value={vencidos}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes Vencidos"
                                    globalFilter={globalFilter}
                                    emptyMessage="Sin lotes vencidos."
                                    loading={loadingVencidos}
                                    header={headerVencidos}
                                >
                                    <Column field="codigo_lote" header="Codigo Lote" sortable></Column>
                                    <Column field="insumo.nombre" header="Nombre" sortable></Column>
                                    <Column field="cantidad" header="Cantidad" sortable></Column>
                                    <Column field="fecha_vencimiento" body={fecha} header="Fecha de Vencimiento" sortable></Column>
                                    <Column field="insumo.grupo.nombre" header="Grupo" sortable></Column>
                                </DataTable>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-12">
                            <div className="card">
                                <DataTable
                                    ref={dt}
                                    value={proximosVencer}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Lotes Proximos a Vencer"
                                    globalFilter={globalFilterProximosVencer}
                                    emptyMessage="Sin Lotes Proximos a Vencer."
                                    loading={loadingProximosVencer}
                                    header={headerProximosVencer}
                                >
                                    <Column field="codigo_lote" header="Codigo Lote" sortable></Column>
                                    <Column field="insumo.nombre" header="Nombre" sortable></Column>
                                    <Column field="cantidad" header="Cantidad" sortable></Column>
                                    <Column field="fecha_vencimiento" body={fecha} header="Fecha de Vencimiento" sortable></Column>
                                    <Column field="insumo.grupo.nombre" header="Grupo" sortable></Column>
                                </DataTable>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-12">
                            <div className="card">
                                <DataTable
                                    ref={dt}
                                    value={quiebresStock}
                                    dataKey="id"
                                    paginator
                                    rows={10}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Insumos con Quibre de Stock"
                                    globalFilter={globalFilterInsumo}
                                    emptyMessage="Sin Insumos con Quiebre de Stock."
                                    loading={loadingInsumos}
                                    header={headerQuiebreStockr}
                                >
                                    <Column field="nombre" header="Nombre" sortable></Column>
                                    <Column field="cantidad" header="Cantidad Actual" sortable></Column>
                                    <Column field="cantidad_minima" header="Cantidad Minima" sortable></Column>
                                    <Column field="grupo.nombre" header="Grupo" sortable></Column>
                                </DataTable>
                            </div>
                        </div>
                        <DialogProcesando visible={loading} />
                        <Dialog visible={productDialog} style={{ width: "600px" }} header="Registrar nueva tempertatura" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <div className="p-field">
                                <label htmlFor="maxima">Temperatura</label>
                                <InputNumber id="maxima" value={temperatura} onChange={(e) => onInputNumberChange(e)} required autoFocus className={classNames({ "p-invalid": submitted && !temperatura })} />
                                {submitted && !temperatura && <small className="p-invalid" style={{ color: 'red' }}>La cantidad es requerida.</small>}
                            </div>
                        </Dialog>
                    </>
                ) : (
                    <>
                        <Redirect to="/solicitud" />
                    </>
                )
            }
        </div>
    );
}
